import axios from "axios";
import { API_BASE_URL } from "./config";
import Cookies from "js-cookie";


// default
axios.defaults.baseURL = API_BASE_URL;

axios.interceptors.request.use((config) => {
  const loginToken = Cookies.get('loginToken') ? JSON.parse(Cookies.get('loginToken')) : {};
  config.headers["authorization"] = loginToken?.idToken || null;
  config.headers["langid"] = 1;
  // config.headers["environment"] = 'Dev';
  config.headers["environment"] = 'Prod';
  return config;
});

// Function to log API errors
const logSystemError = async (originalRequest, response) => {
  const payload = {
    UserID: 796,
    RequestBody: JSON.stringify(originalRequest.data || {}),
    ReponseBody: JSON.stringify(response?.data || {}),
    IsSocket: false,
    ApiEndPoint: originalRequest.url,
    Platform: "Web",
  };
  try {
    await axios.post(`${API_BASE_URL}/Systemlogs`, payload);
  } catch (error) {
    console.error("Failed to log system error:", error);
  }
};


// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const { response, config: originalRequest } = error;
    if (response?.status !== 200) {
      logSystemError(originalRequest, response);
    }

    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

class APIClient {
  /**
   * Fetches data from given url
   */
   get = (url, params) => {
    return axios.get(url, params);
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export { APIClient };