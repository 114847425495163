import React, { useEffect } from 'react'

const ClassComplete = () => {
  useEffect(() => {
    setTimeout(() => {
      window.close();  // Close the current tab
      // window.history.back();  // Navigate to the previous tab or page
    }, 2000)
  }, [])


  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px'}}>
      <h2>Your class has been completed</h2>
    </div>
  )
}

export default ClassComplete