import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateAgoraToken, decryptData, getLocalizationData } from '../services/service';
import Cookies from "js-cookie";

import "../App.css";

const StudentCourse = () => {
    const navigate = useNavigate()
    const { sessionID, userUid, idToken, deviceId } = useParams();
    const sessionId = decryptData(sessionID);

    const handleJoin = () => {
        const tutorResponseData = {};
        tutorResponseData["TutorID"] = '796';
        tutorResponseData["TutorName"] = 'Admin';
        tutorResponseData["TutorImage"] = 'https://storage.googleapis.com/yasa-admin_images/StudentAvatar/MaleDefaultImg.png';
        tutorResponseData["StudentID"] = '0';
        tutorResponseData["StudentName"] = 'Admin';
        tutorResponseData["StudentImage"] = 'https://storage.googleapis.com/yasa-admin_images/StudentAvatar/MaleDefaultImg.png';
    
        sessionStorage.setItem("TutorResponse", JSON.stringify(tutorResponseData));
        sessionStorage.setItem("TrainingType", "StudentCourse");
        sessionStorage.setItem("SessionID", sessionId);
        sessionStorage.setItem("deviceId", deviceId);
        localStorage.setItem("SessionType", "SC");
        localStorage.setItem("CallType", 4);
        sessionStorage.setItem("RoleType", 2);

        const profileInfo = {
            UserName: 'Admin',
            ProfilePictureUrl: 'https://storage.googleapis.com/yasa-admin_images/StudentAvatar/MaleDefaultImg.png'
        }

        Cookies.set('userInfo', JSON.stringify({uid: userUid, displayName: 'Admin'}));
        Cookies.set('ProfileInfo', JSON.stringify(profileInfo));
        Cookies.set('loginToken', JSON.stringify({idToken: idToken}));

        generateAgoraToken(userUid, 2, `channel${sessionId}`).then((res) => {
            localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
            localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
            navigate(`/hire-class-room/${sessionID}`)
        })
    }

    useEffect(() => {
        getLocalizationData();
        setTimeout(() => {
         handleJoin();
        }, 500)
           
    }, [])

  return (
    <>
        <div className="loader-box">
            <div className="loader"></div>
        </div>
        <h1 className="text-center">Loading...</h1>
    </>
  )
}

export default StudentCourse;