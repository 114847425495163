import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

import { decryptData, agoraSessionStart, agoraSessionEnd } from "../services/service";

const LaunchTutorSession = () => {
  const { sessionID } = useParams();
  const trainingType = sessionStorage.getItem("TrainingType");
  const userInfo = JSON.parse(Cookies.get('userInfo'));
  const userUid = userInfo?.uid;
  const sessionId = decryptData(sessionID);
  const agoraCallDuration = 3600 ;
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName =  trainingType === 'StudentCourse' ? `studentcourses${sessionId}` : trainingType === 'Private' ? `TrainingMode${sessionId}` : `meeting${sessionId}`;
  const roomUuid = trainingType === 'StudentCourse' ? `studentcoursesuuid${sessionId}` : trainingType === 'Private' ? `TrainingModeuuid${sessionId}` : `meetinguuid${sessionId}`;
  const channel = `channel${sessionId}`;
  const roomType = 4;
  const userName = 'Admin';

  useEffect(() => {

    const params = {
      userUid,
      userName,
      roomUuid,
      sessionId,
      roomName,
      channel,
      rtmToken,
      roomType,
      agoraCallDuration,
      agoraSessionStart,
      agoraSessionEnd,
    };
    test(params); 

  }, []);
  return;
};
export default LaunchTutorSession;
