import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateAgoraToken, decryptData, getLocalizationData } from '../services/service';
import Cookies from "js-cookie";

import "../App.css";

const PrivateTutorTraining = () => {
    const navigate = useNavigate()
    const { sessionID, userUid, idToken, deviceId, AdminID, AdminName, AdminProfilePic, TutorID, TutorName, TutorProfilePic } = useParams();
    const sessionId = decryptData(sessionID);

    const handleJoin = () => {
        const tutorResponseData = {};
        tutorResponseData["TutorID"] = AdminID;
        tutorResponseData["TutorName"] = AdminName;
        tutorResponseData["TutorImage"] = "https://storage.googleapis.com/yasa-admin_images/MaleDefaultImg.png";
        tutorResponseData["StudentID"] = TutorID;
        tutorResponseData["StudentName"] = TutorName;
        tutorResponseData["StudentImage"] = "https://storage.googleapis.com/yasa-admin_images/MaleDefaultImg.png";
    
        sessionStorage.setItem("TutorResponse", JSON.stringify(tutorResponseData));
        sessionStorage.setItem("TrainingType", "Private");
        sessionStorage.setItem("SessionID", sessionId);
        sessionStorage.setItem("deviceId", deviceId);
        localStorage.setItem("SessionType", "TSS");
        localStorage.setItem("CallType", 4);

        const profileInfo = {
            ID: AdminID,
            UserName: AdminName,
            ProfilePictureUrl: "https://storage.googleapis.com/yasa-admin_images/MaleDefaultImg.png"
        }

        Cookies.set('userInfo', JSON.stringify({uid: userUid, displayName: 'Admin'}));
        Cookies.set('ProfileInfo', JSON.stringify(profileInfo));
        Cookies.set('loginToken', JSON.stringify({idToken: idToken}));

        generateAgoraToken(userUid, 1, `channel${sessionId}`).then((res) => {
            localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
            localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
            navigate(`/hire-class-room/${sessionID}`)
        })
    }

    useEffect(() => {
       getLocalizationData();
       setTimeout(() => {
        handleJoin()
       }, 500)
          
    }, [])

  return (
    <>
        <div className="loader-box">
            <div className="loader"></div>
        </div>
        <h1 className="text-center">Loading...</h1>
    </>
  )
}

export default PrivateTutorTraining